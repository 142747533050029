import { get, post } from "@/utils/request";
// 查询app内信息
export function pageappnotice(params) {
    // 使用 URLSearchParams 构建查询参数
    const queryParams = new URLSearchParams(params).toString();
    // 拼接 URL
    const url = `/app/user/pageappnotice?${queryParams}`;
    // 发送 GET 请求
    return get(url);
}
// 分页查询 app 客服列表
export function pageCustomer(params) {
    // 使用 URLSearchParams 构建查询参数
    const queryParams = new URLSearchParams(params).toString();
    // 拼接 URL
    const url = `/app/user/pageappnotice?${queryParams}`;
    // 发送 GET 请求
    return get(url);
}
// 分页查询 用户已读信息列表
export function pagereadnotice(params) {
    // 使用 URLSearchParams 构建查询参数
    const queryParams = new URLSearchParams(params).toString();
    // 拼接 URL
    const url = `/app/user/pagereadnotice?${queryParams}`;
    // 发送 GET 请求
    return get(url);
}
// 用户已读取消息接口
export function userReadNotice(data) {
    return post("/app/user/userreadnotice", data);
}
