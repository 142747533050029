import { defineComponent, reactive, toRefs, onMounted } from "vue";
import { useRouter } from "vue-router";
import { pageappnotice, userReadNotice } from "@/api/appNotice"; // 导入 pageappnotice 和 userReadNotice 接口

import { hideLoading, showImg, showLoading } from "@/utils/utils";
export default defineComponent({
  name: "HOME",

  setup() {
    const router = useRouter();
    const banners = [require("@/assets/images/banner1.jpg"), require("@/assets/images/banner2.jpg"), require("@/assets/images/banner3.jpg"), require("@/assets/images/banner4.jpg")];
    const state = reactive({
      tutorialList: [],
      newsList: [],
      active: "/home" // 用于跟踪当前选中的Tab

    }); // 获取用户信息

    const userInfoString = localStorage.getItem("userInfo");
    const userInfo = userInfoString ? JSON.parse(userInfoString) : null;

    const toDetail = async item => {
      if (!userInfo || !userInfo.loginName) {
        console.error("LoginName is missing from localStorage.");
        return;
      }

      try {
        // 调用用户已读消息接口
        const res = await userReadNotice({
          loginName: userInfo.loginName,
          noticeId: item.id // 当前教程或新闻的 id

        });

        if (res.code === 1) {
          // 接口调用成功后跳转到详情页面
          router.push({
            path: "/home/newTutoria/details",
            query: {
              data: JSON.stringify(item)
            }
          });
        } else {
          console.error("Failed to mark notice as read", res.msg);
        }
      } catch (error) {
        console.error("Error marking notice as read", error);
      }
    }; // 跳转页面


    const toDetail2 = path => {
      router.push(path);
      state.active = path; // 跳转并设置active为"/accountList"
    };

    const toDetail3 = path => {
      window.location.href = path; // 强制重定向并刷新页面
    }; // 调用 pageappnotice 接口获取数据


    const fetchPageAppNotice = async () => {
      showLoading("Loading data..."); // 显示加载状态

      try {
        // 获取 type=1 (Tutorial) 的数据，并截取前两个
        const tutorialResponse = await pageappnotice({
          type: "1",
          current: 1,
          size: 2
        });

        if (tutorialResponse.code === 1) {
          state.tutorialList = tutorialResponse.data.records.slice(0, 2); // 取前两条数据
        } // 获取 type=0 (News) 的数据，并截取前三个


        const newsResponse = await pageappnotice({
          type: "0",
          current: 1,
          size: 3
        });

        if (newsResponse.code === 1) {
          state.newsList = newsResponse.data.records.slice(0, 3); // 取前三条数据
        }
      } catch (error) {
        console.error("获取数据失败", error);
      } finally {
        hideLoading(); // 无论成功还是失败都隐藏加载状态
      }
    }; // 生命周期钩子，在页面加载时调用接口获取数据


    onMounted(() => {
      fetchPageAppNotice();
    });
    return { ...toRefs(state),
      banners,
      toDetail,
      showImg,
      toDetail2,
      toDetail3
    };
  }

});